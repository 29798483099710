<template>
    <div class="content">
        <h1>通知进度详情</h1>
        <div class="list">
            <el-steps :active="currentHandler + 1" align-center>
                <el-step v-for="(item, index) in noticeList" :key="index" :title="item.name" :description="item.time">
                    <template slot="icon">
                        <img src="@/assets/imgs/finish.png" alt="已处理" />
                    </template>
                </el-step>
            </el-steps>
        </div>
    </div>
</template>

<script>
import { getRiskEventNoticeProgress } from '@/api/bedManage'
export default {
    props: {
        id: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            currentHandler: 0,
            noticeList: []
        }
    },
    created() {
        // this.id = this.$route.params.id
        // console.log(this.id);
        this.getRiskEventProgress()
    },
    methods: {
        async getRiskEventProgress() {
            const params = {
                riskId: this.id
            }
            const res = await getRiskEventNoticeProgress(params)
            this.noticeList = res.data
            this.currentHandler = this.noticeList.length
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    h1 {
        text-align: center;
        font-size: 18px;
    }
    .list {
        text-align: center;
        .user {
            display: inline-block;
        }
        p {
            display: inline-block;
            width: 60px;
            height: 5px;
            background-color: green;
        }
        img {
            width: 24px;
        }
    }
}
</style>
